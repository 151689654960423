<template>
  <div
    class="group bg-ems-tag1 hover:bg-ems-gray700 border-1 !hover:border-ems-gray500 !border-ems-tag1 box-border min-h-159px rounded-8px p-20px flex flex-col 2xl:flex-row items-center gap-20px relative"
  >
    <div class="w-120px h-120px object-cover">
      <img :src="item.imgPath" alt="ExampleImg2" />
    </div>
    <div class="flex flex-col items-center 2xl:items-start gap-12px">
      <a-tooltip :title="item.id" placement="top">
        <div
          class="text-18px font-semibold text-ems-gray100 w-max-[140px] truncate"
        >
          {{ item.id }}
        </div>
      </a-tooltip>
      <div class="flex">
        <a
          :href="`https://www.facebook.com/${item.id}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            :class="`bg-ems-facebook text-16px text-ems-gray100 leading-25px px-10px py-3px rounded-4px`"
          >
            Facebook
          </div>
        </a>
      </div>
      <div class="flex">
        <div
          :class="`py-3px text-16px ${
            item && formatScore(item.score) > 65
              ? 'text-ems-boTro4_600'
              : 'text-ems-main2'
          }`"
        >
          {{ item && formatScore(item.score) }}%
        </div>
      </div>
    </div>
    <div
      class="absolute top-12px right-12px flex flex-col hidden group-hover:block"
    >
      <div v-if="isShowOptions" class="shadow-custom rounded-8px">
        <div
          class="text-ems-gray100 flex items-center px-16px py-20px gap-14px cursor-pointer bg-ems-gray700 hover:bg-ems-gray600 rounded-8px"
        >
          <cds-feather-icons type="eye" size="16" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatScore } from '@/util/common-utils';
const { t } = useI18n();
const props = defineProps({
  item: {
    default: {},
  },
});
const isShowOptions = ref(false);
const hideOptions = () => {
  if (isShowOptions.value) {
    isShowOptions.value = false;
  }
};
</script>
<style scoped>

.shadow-custom {
  box-shadow: 0 4px 84px 0 #000000;
}

</style>
