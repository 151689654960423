<template>
  <div
    class="group bg-ems-tag1 hover:bg-ems-gray700 border-1 !hover:border-ems-gray500 !border-ems-tag1 box-border min-h-[190px] rounded-8px p-20px flex flex-col items-center gap-20px relative mt-4"
  >
    <div class="flex justify-between w-full items-center">
      <p class="text-ems-gray100 font-semibold text-[14px]">{{ item.id }}</p>
      <div class="flex space-x-[23px]">
        <a
          :href="`https://www.facebook.com/${item.id}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            :class="`bg-ems-facebook text-16px text-ems-gray100 leading-25px px-10px py-3px rounded-4px`"
          >
            Facebook
          </div>
        </a>
        <!-- <div
          :class="`text-16px text-ems-gray100 leading-25px px-10px py-3px rounded-4px flex border border-ems-gray300 space-x-[10px]`"
        >
          <img src="../../../static/img/icon/all-image.svg" alt="IconSearch" />
          <p>{{ t('object_information.view-all-images') }}</p>
        </div> -->
      </div>
    </div>
    <div class="w-full h-[0.5px] bg-ems-gray600" />
    <div class="flex items-start justify-start w-full space-x-3">
      <div class="w-[20%] w-[105px] h-[127px] flex-shrink-0">
        <img alt="avatar" :src="item.imgPath[0]" class="w-auto h-[127px]" />
      </div>
      <div class="grid grid-cols-14 gap-3">
        <div
          v-for="(item, index) in item.imgPath.slice(1, 29)"
          class="min-h-[41.5px] min-w-[56.36px]"
        >
          <img alt="avatar" :src="item" class="w-full h-auto flex-shrink-0" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatScore } from '@/util/common-utils';
const { t } = useI18n();
const props = defineProps({
  item: {
    default: {},
  },
});
const isShowOptions = ref(false);
const hideOptions = () => {
  if (isShowOptions.value) {
    isShowOptions.value = false;
  }
};
</script>
<style scoped>

.shadow-custom {
  box-shadow: 0 4px 84px 0 #000000;
}

</style>
